export const getAllApprovalsComponent = () => {
  return import(
    './AllApprovals/components/AllApprovalsComponent/AllApprovalsComponent'
  )
}

export const getPreviewAllApprovalsComponent = () => {
  return import(
    './AllApprovals/components/PreviewAllApprovalsComponent/PreviewAllApprovalsComponent'
  )
}
